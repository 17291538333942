<template>
  <div>
    <div class="search" style="display: flex;justify-content: space-between;">
      <div>
        <div class="a-inline-block">
          <label for="">团队</label>
          <a-input size="large" @focus="focus" @click="visible=true" v-model="teamorText"></a-input>
          <!-- <a-select
                    v-model="teamor"
                    placeholder="请选团队"
                    style="width: 200px"
                >
                   
                    <a-select-option
                        v-for="(item, index) in teamList"
                        :key="index"
                        :value="item.did"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select> -->

        </div>
        <div class="a-inline-block">
          <label for="">时间</label>
          <a-select size="large" v-model="type">
            <a-select-option :value="1">年</a-select-option>
            <a-select-option :value="2">月</a-select-option>
            <a-select-option :value="3">周</a-select-option>
          </a-select>
        </div>

        <div class="a-inline-block">
          <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
          <a-button size="large" @click="reset">重置</a-button>
        </div>
      </div>

      <!-- <a-button size="large" type="primary" @click="addTarget" style="width:auto">添加团队目标</a-button> -->
      <a-button class="addBtn" size="large" type="primary" style="width:auto;" @click="delAll">批量删除</a-button>
    </div>

    <a-table :columns="columns" :data-source="data" class="table" :pagination="pagination" :row-selection="rowSelection"
      :rowKey="record=> record.id">
      <span slot="num" slot-scope="text, record, index">
        {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
      </span>
      <template slot="Action" slot-scope="text, record">
        <!-- <a
                    class="action"
                    href="javascript:;"
                    @click="showDetail(record.id)"
                    >查看</a
                > -->

        <a class="action" href="javascript:;" @click="showDetail(record.id, 2)">编辑</a>
        <!-- <a-popconfirm title="确定要关闭吗?" @confirm="() => off(record.id)">
                <a class="action del" href="javascript:;">关闭</a>
            </a-popconfirm> -->
        <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
          <a class="action del" href="javascript:;">删除</a>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal v-model="visible" title="添加分类" @ok="confirmTeamor">
      <div class="flex">
        <a-tree :selected-keys="selectedKeys" :expanded-keys="expandedKeys" @select="onSelect" @expand="onExpand"
          :tree-data="treeData" />
        <div>
          <p>已选部门</p>
          <span v-if="teamorSel">{{teamorSel[0].name}}</span>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
let timeOnj = [
  { key: 1, label: "年" },
  { key: 2, label: "月" },
  { key: 3, label: "周" },
];
// import $ from "jquery";
import moment from "moment";
import { requestXml } from "../../../assets/js/request";
export default {
  data() {
    return {
      clientList: [], //员工列表
      staff_id: "", //员工id
      visible: false,
      treeData: [
        {
          title: "0-0",
          key: "0-0",
          children: [
            {
              title: "0-0-0",
              key: "0-0-0",
              children: [
                { title: "0-0-0-0", key: "0-0-0-0" },
                { title: "0-0-0-1", key: "0-0-0-1" },
                { title: "0-0-0-2", key: "0-0-0-2" },
              ],
            },
          ],
        },
      ],
      expandedKeys: [1],
      selectedKeys: [],
      teamor: "", //团体id
      teamorText: "",
      teamList: [], //团体列表
      teamorSel: "",
      key: "", //执行团队
      type: null,
      columns: [
        {
          title: "序号",
          dataIndex: "num",
          width: 70,
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        { title: "目标的执行人", dataIndex: "stname", key: "7" },
        { title: "时间类型", dataIndex: "timetype", key: "6" },
        {
          title: "目标执行时间",
          dataIndex: "createtime",
          width: 300,
          key: "8",
        },
        // { title: "新客数", dataIndex: "newcustomers", key: "2" },
        // { title: "签单数", dataIndex: "signlistnum", key: "1" },
        { title: "开票金额", dataIndex: "signlistmoney", key: "3" },
        // { title: "回款数", dataIndex: "paymentnum", key: "4" },
        { title: "回款金额", dataIndex: "paymentmoney", key: "5" },

        {
          title: "操作",
          key: "operation",
          fixed: "right",

          scopedSlots: { customRender: "Action" },
        },
      ],
      data: [],
      selectedRows: "",
      rowSelection: {
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
          this.selectedRows = selectedRows;
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
          this.selectedRows = selectedRows;
        },
      },
      // 分页
      pagination: {
        current: 1,
        total: 100,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showQuickJumper: true,
        showTotal: total => `共 ${total} 条`, // 显示总数
        onChange: (current) => this.changePage(current), //点击当前页码
        onShowSizeChange: (current, pageSize) => {
          //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
          console.log(pageSize);
          this.showSizeChange(current, pageSize);
        },
      },
    };
  },
  mounted: function () {
    this.getClientList();
    this.getTask();
    this.getDepartment();
  },
  methods: {
    focus(e) {
      e.srcElement.blur();
    },
    // 获取部门
    getDepartment() {
      requestXml("/scrm/Index/getDepartment", "GET", (res) => {
        this.treeData = res;
      });
    },
    confirmTeamor() {
      this.teamor = this.teamorSel[0].did;
      this.teamorText = this.teamorSel[0].name;
      this.visible = false;
    },
    onSelect(keys) {
      this.selectedKeys = keys;
      this.teamorSel = this.teamList.filter((item) => item.did == keys);
    },
    onExpand(keys) {
      this.expandedKeys = keys;
    },
    // 获取 简化成员列表
    getClientList() {
      requestXml("/jieb/Target/department", "POST", (res) => {
        console.log("teamListteamList", res);
        // this.teamor = res[0].did;
        this.teamList = res;
      });
    },
    addTarget() {
      this.$router.push({
        path: "/scrm_pc/target/addgroup",
      });
    },
    //获取时间
    getTime(time) {
      let current = timeOnj.filter((item) => {
        return item.key == time;
      });
      return current;
    },

    // 搜索
    onSearch() {
      this.pagination.current=1;
      this.getTask(this.key, this.type);
    },
    reset() {
      this.key = "";
      this.type = null;
      this.status = null;
      this.teamor = "";
      this.teamorText = "";
      this.selectedKeys = [];
      this.teamorSel = "";
      this.expandedKeys = [1];
    },
    getTask() {
      let times = this.getTime(this.type);
      let postdata = {
        timetype: "",
        department_id: this.teamor,
        type: 2,
      };
      if (times[0]?.key) {
        postdata.timetype = times[0]?.label;
      }

      requestXml(
        "/jieb/Target/show",
        "POST",
        (res) => {
          for (let i = 0; i < res.list.length; i++) {
            res.list[i].createtime = moment(res.list[i].createtime).format(
              "YYYY-MM-DD"
            );
            res.list[i].endtime = moment(res.list[i].endtime)
              // .subtract(1, "days")
              .format("YYYY-MM-DD");
            res.list[i].createtime =
              res.list[i].createtime + " 至 " + res.list[i].endtime;
          }
          this.pagination.total = Number(res.page.total);
          this.pagination.pageSize = Number(res.page.pagesize);
          this.data = res.list;

          console.log("this.data", res);
          // console.log('this.data',res.data.list);
        },
        {
          ...postdata,
          page: {
            curpage: this.pagination.current,
            // pagesize: 999,
            pagesize: this.pagination.pageSize,
          },
        }
      );
    },
    // 分页
    changePage(current) {
      //current参数表示是点击当前的页码，
      this.pagination.current = current;
      this.getTask(); //向后端发送请求
    },
    showSizeChange(current, pageSize) {
      console.log(current, pageSize);
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getTask(); //向后端发送请求
    },
    // 查看 type 1 查看 2 编辑
    showDetail(id, type = 1) {
      this.$router.push({
        path: "/scrm_pc/target/groupdetail",
        query: {
          id: id,
          type: type,
        },
      });
    },
    // 编辑
    editTask(id) {
      this.$router.push({
        path: "/scrm_pc/task/taskAdd",
        query: {
          id: id,
        },
      });
    },
    // 关闭
    off(id) {
      requestXml(
        "/scrm/Task/setClose",
        "POST",
        (res) => {
          console.log(res);
          this.getTask();
        },
        { id: id }
      );
    },
    // 删除
    onDelete(id) {
      requestXml(
        "/jieb/Target/personaldel",
        "POST",
        (res) => {
          console.log(res);
          this.getTask();
        },
        { id: id }
      );
    },
    delAll() {
      if (this.selectedRows.length < 1) {
        this.$message.info("请先选择");
        return false;
      } else {
        let idArr = [];
        let sel = this.selectedRows;
        for (let i = 0; i < sel.length; i++) {
          idArr.push(sel[i].id);
        }
        this.onDelete(idArr);
      }
    },
  },
  watch: {
    data(val) {
      console.log("valval", val);
    },
  },
};
</script>
<style scoped>
.action {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px;
  background: #dceeff;
  border: 1px solid #36a3ff;
  color: #36a3ff;
  border-radius: 5px;
}
.del {
  background: #ffdcdc;
  border-color: #ff4c4c;
  color: #ff4c4c;
}
.ant-tree {
  width: 50% !important;
}
</style>

